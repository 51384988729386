import React from 'react';
import { Link, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Playlist from '../components/playlist';
import { addHotspot } from '../utils/imageUrl';
import { defaultSerializers } from '../utils/serializers';
import PlaylistEmbed from '../embeds/playlistEmbed';
import get from 'lodash/get';
import { stringify } from 'query-string';
import { FaTwitter, FaFacebook, FaEnvelope } from 'react-icons/fa';

function ProjectShare({ title, slug, box = false }) {
  return (
    <aside
      style={{
        opacity: box ? 1 : 0.7,
        fontWeight: 'bold',
        padding: box ? '1em' : 0,
        borderRadius: '6px',
        background: box ? '#fff' : 'transparent',
        marginBottom: '2em',
      }}
    >
      Share{box ? ' this project on' : ''}:{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share on Twitter"
        target="_blank"
        rel="noopener"
        href={`https://twitter.com/intent/tweet?${stringify({
          text: `"${title}" by @nicolettemmusic \n\n https://nicolette.me/projects/${slug}/`,
        })}`}
      >
        <FaTwitter size="1.5em" color="#444" />
      </a>{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share on Facebook"
        target="_blank"
        rel="noopener"
        href={`https://www.facebook.com/sharer/sharer.php?${stringify({
          u: `https://nicolette.me/projects/${slug}/`,
        })}`}
      >
        <FaFacebook size="1.5em" color="#444" />
      </a>{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share by Email"
        href={`mailto:?${stringify({
          subject: title,
          body: `"${title}" by Nicolette Macleod\n\nhttps://nicolette.me/projects/${slug}/\n\n `,
        })}`}
      >
        <FaEnvelope size="1.5em" color="#444" />
      </a>{' '}
    </aside>
  );
}

function ProjectEntry(props) {
  const { data } = props;
  const {
    title,
    _rawContent,
    mainImage,
    featuredPlaylist,
    slug,
  } = data.sanityProject;
  return (
    <Layout
      dominantColour={get(
        mainImage,
        'asset.metadata.palette.darkMuted.background'
      )}
      textColour={get(mainImage, 'asset.metadata.palette.darkMuted.title')}
      sidebar={
        featuredPlaylist ? (
          <PlaylistEmbed slug={featuredPlaylist.slug.current} />
        ) : (
          undefined
        )
      }
      headerImage={
        mainImage
          ? addHotspot(mainImage.asset.fluid.src, mainImage.hotspot)
          : undefined
      }
    >
      <SEO
        title={`Nicolette Macleod - Projects: ${title}`}
        twitterCard="summary_large_image"
        image={
          mainImage
            ? addHotspot(mainImage.asset.fixed.src, mainImage.hotspot)
            : undefined
        }
      />
      <article className="page-content page-content--project">
        <header
          style={{
            borderBottom: '1px solid #ccc',
            paddingBottom: '3em',
            marginBottom: '3em',
          }}
        >
          <Link
            className="journal__back-link"
            to={`/projects/#project-${slug.current}`}
          >
            projects /
          </Link>{' '}
          <h1 style={{ marginTop: '0.25em' }}>{title}</h1>
        </header>
        <BlockContent blocks={_rawContent} serializers={defaultSerializers} />
      </article>
      <ProjectShare title={title} slug={slug.current} box />

      <Link to={`/projects/#project-${slug.current}`}>Back to Projects</Link>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    sanityProject(slug: { current: { eq: $slug } }) {
      id
      title
      slug {
        current
      }
      featuredPlaylist {
        slug {
          current
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      mainImage {
        hotspot {
          x
          y
        }
        asset {
          metadata {
            palette {
              darkMuted {
                background
                title
              }
            }
          }
          fixed(width: 2000, height: 1000, toFormat: JPG) {
            ...GatsbySanityImageFixed
          }
          fluid(maxWidth: 1600, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

export default ProjectEntry;
